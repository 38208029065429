import { HeadProps, Link } from 'gatsby'
import * as React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import WidthLimiter from '../components/widthLimiter/widthLimiter'

const DataProtectionPage = () => {
  return (
    <Layout>
      <WidthLimiter>
        <h2>Impressum</h2>
        <br />
        <strong>KEON GmbH</strong>
        <div>
          <Link to="https://www.keon.ch">keon.ch</Link>
        </div>
        <div>Technoparkstrasse 1</div>
        <div>8005 Zürich</div>
        <div>hello@keon.ch</div>
        <div>Tel. +41 44 545 12 46</div>
        <br />
      </WidthLimiter>
    </Layout>
  )
}

export const Head = ({ location }: HeadProps) => (
  <Seo
    title="Impressum"
    description="Wer verbirgt sich hinter hyppi.ch"
    pathname={location.pathname}
    noindex={true}
  />
)

export default DataProtectionPage
